<template>
  <div>
    <maca-modal
      titulo="Modificar Usuario"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        v-loading="cargando"
        label-position="top"
        label-width="130px"
      >
        <el-form-item label="DNI" prop="dni">
          <el-input maxlength="9" v-model="form.dni"></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="15">
            <el-form-item label="Razon Social" prop="razonSocial">
              <el-input maxlength="20" v-model="form.razonSocial"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <el-form-item label="CUIT/CUIL" prop="cuit">
              <el-input maxlength="11" v-model="form.cuit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>

        <el-form-item label="Apellido" prop="apellido">
          <el-input v-model="form.apellido"></el-input>
        </el-form-item>

        <el-form-item label="Email" prop="email">
          <el-input
            v-model="form.email"
            class="inputEmail"
            maxlength="300"
          ></el-input>
          <label style="font-style: italic; color: grey; font-size: 14px">
            Este email, será la contraseña por defecto del usuario.</label
          >
        </el-form-item>

        <el-form-item label="Ubicación actual" v-if="ubicacionActual">
          <strong>
            <span>
              <span v-if="this.form.departamento != null">{{
                this.form.departamento.nombre
              }}</span
              >,
              <span v-if="this.form.localidad != null">{{
                this.form.localidad.nombre
              }}</span>
              ,
              <span v-if="this.form.paraje != null">{{
                this.form.paraje.nombre
              }}</span>
            </span></strong
          >
        </el-form-item>

        <el-form-item label="Ubicación">
          <el-cascader
            :props="props"
            :show-all-levels="false"
            placeholder="Seleccionar ubicación"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <span v-if="ubicacionIncompleta" style="color: red"
          >Debe seleccionar, al menos, hasta la localidad. De lo contrario, se
          mantendrá la ubicación actual</span
        >

        <el-form-item label="Rol" prop="nombre">
          <!-- <maca-select-box v-model="form.rol" :url="urlRol"></maca-select-box> -->
          <el-select v-model="form.rol" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="Recibir Notificaciones" prop="notificacion">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueNotificacion"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 3) {
            //  form.departamento = node.data.value;

            let params = { departamentoID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            //  form.localidad = node.data.value;
            let params = { localidadID: node.data.value, sinPaginar: 1 };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      urlRol: "/rol/obtenerTodos",

      idRolActual: null,

      valueNotificacion: true,

      form: {
        dni: null,
        cuit: null,
        razonSocial: null,
        nombre: null,
        apellido: null,
        email: null,
        pais: null,
        provincia: null,
        departamento: null,
        localidad: null,
        paraje: null,
        rol: null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      persona: null,
      ubicacionActual: true,
      valueFormatoDecimales: true,
      ubicacionIncompleta: false,
      options: [],
      formRules: {
        dni: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        cuit: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        razonSocial: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email de usuario.",
            trigger: "change",
          },
        ],

        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        rol: [
          {
            required: true,
            message: "Por favor introduzca el rol.",
            trigger: "change",
          },
        ],
      },

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,
    };
  },
  methods: {
    async getRoles() {
      this.options = [];
      let respuestaApi = await this.$maca.api.get(this.urlRol, {
        rol: "SISTEMA",
      });
      if (respuestaApi != null) {
        respuestaApi.datos.data.forEach((element) => {
          this.options.push({
            value: element.id,
            label: element.nombre,
          });
        });
      }
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
      }
    },
    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    resetearValor(valor) {
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.form.localidad = null;
      }
    },
    async abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      await this.getRoles();
      this.id = id;
      await this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get("/user/obtenerDatos", {
        id: this.id,
      });

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.detallePersona.nombre;
        this.form.apellido = respuestaApi.datos.detallePersona.apellido;
        this.form.email = respuestaApi.datos.detallePersona.email;
        this.form.dni = respuestaApi.datos.detallePersona.dni;
        this.form.cuit = respuestaApi.datos.detallePersona.cuitCuil;
        this.form.razonSocial = respuestaApi.datos.detallePersona.razonSocial;
        this.idRolActual = respuestaApi.datos.detalleRol[0].rolID;
        this.form.paraje = respuestaApi.datos.detallePersona.detalleParaje;

        this.form.paraje = respuestaApi.datos.detallePersona.detalleParaje;
        this.form.localidad =
          respuestaApi.datos.detallePersona.detalleLocalidad;
        this.form.departamento =
          respuestaApi.datos.detallePersona.detalleDepartamento;
        this.form.provincia =
          respuestaApi.datos.detallePersona.detalleProvincia;

        this.form.rol = respuestaApi.datos.detalleRol[0].rolID;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleRol = [
        { rolID: this.idRolActual, accion: "B" },
        { rolID: this.form.rol, accion: "A" },
      ];

      console.log(this.localidadID, this.parajeID);
      // Hacer Post
      let params = {
        id: this.id,
        detalleRol: JSON.stringify(detalleRol),
        email: this.form.email,
        dni: this.form.dni,
        cuitCuil: this.form.cuit,
        apellido: this.form.apellido,
        nombre: this.form.nombre,
        razonSocial: this.form.razonSocial,
        recibirNotificaciones: this.valueNotificacion,
        localidadID: this.localidadID != null ? this.localidadID : "",
        parajeID: this.parajeID != null ? this.parajeID : "",
      };

      let respuestaApi = await this.$maca.api.post("/user/actualizar", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Usuario actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
