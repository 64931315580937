<template>
  <div>
    <maca-modal titulo="Nuevo Usuario" :impedirClose="impedirClose" ref="modal">
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="160px"
      >
        <el-form-item label="DNI" prop="dni">
          <el-input v-model="form.dni" maxlength="9"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Razon Social" prop="razonSocial">
              <el-input v-model="form.razonSocial" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CUIT/CUIL" prop="cuit">
              <el-input v-model="form.cuit" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Apellido" prop="apellido">
          <el-input v-model="form.apellido"></el-input>
        </el-form-item>

        <el-form-item label="Email" prop="email">
          <el-input class="inputEmail" maxlength="300" v-model="form.email"></el-input>

          <label
            style="font-style: italic; color: grey; font-size: 14px"
          >Este email, será la contraseña por defecto del usuario.</label>
        </el-form-item>

        <el-form-item label="Ubicación">
          <el-cascader
            :props="props"
            :show-all-levels="false"
            placeholder="Seleccionar ubicación"
            @change="handleChange"
          ></el-cascader>
          <label v-if="ubicacionIncompleta" style="color: red">
            Debe seleccionar, al menos, hasta la localidad. De lo contrario, no
            se cargará
          </label>
        </el-form-item>

        <el-form-item label="Rol" prop="rol">
          <el-select v-model="form.rol" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="Recibir Notificaciones" prop="notificacion">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueNotificacion"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            console.log(node.data.label);
            console.log(node.data.value);

            let params = { paisID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 3) {
            //  form.departamento = node.data.value;

            let params = { departamentoID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            //  form.localidad = node.data.value;
            let params = { localidadID: node.data.value, sinPaginar: 1 };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      valueNotificacion: true,
      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,

      form: {
        dni: null,
        cuit: null,
        razonSocial: null,
        nombre: null,
        apellido: null,
        email: null,
        pais: null,
        provincia: null,
        departamento: null,
        localidad: null,
        rol: null
      },
      impedirClose: false,
      persona: null,

      options: [],
      urlRol: "/rol/obtenerTodos",

      formRules: {
        dni: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        cuit: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        razonSocial: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email de usuario.",
            trigger: "change",
          },
        ],

        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        rol: [
          {
            required: true,
            message: "Por favor introduzca el rol.",
            trigger: "change",
          },
        ],
      },

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      let respuestaApi = await this.$maca.api.get(this.urlRol, {
        rol: "SISTEMA"
      });
      if (respuestaApi != null) {
        respuestaApi.datos.data.forEach(element => {
          this.options.push({
            value: element.id,
            label: element.nombre
          });
        });

      }
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    resetearValor(valor) {
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.form.localidad = null;
      }
    },
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleRol = [{ rolID: this.form.rol, accion: "A" }];
      // Hacer Post
      let params = {
        detalleRol: JSON.stringify(detalleRol),
        localidadID: this.form.localidad != null ? this.form.localidad.id : "",
        email: this.form.email,
        dni: this.form.dni,
        cuitCuil: this.form.cuit,
        apellido: this.form.apellido,
        nombre: this.form.nombre,
        razonSocial: this.form.razonSocial,
        recibirNotificaciones: this.valueNotificacion,
      };

      let respuestaApi = await this.$maca.api.post("/user/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Empleado creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>
